<template>
    <div>

      <slot name="button" :onOpenDrawer="openDrawer">
        <v-btn icon color="primary" @click="openDrawer">
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </slot>
  
      <v-navigation-drawer v-model="drawer" temporary right width="500" app>
        <template v-slot:prepend>
          <v-container>
            <v-row class="close-row">
              <v-col cols="11">
                <v-btn icon @click="drawer = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="d-flex justify-center pa-3">
                <v-btn block color="info" @click="onApplyFilter">
                  <v-icon left>mdi-filter</v-icon>
                  {{ tLabel("Apply") }}
                </v-btn>
              </v-col>
            </v-row>
  
            <v-divider></v-divider>
  
            <v-row align="center" class="mb-4">
              <v-col cols="12">
                <v-combobox :label="tLabel('Vessel')" v-model="vesselSelected" :placeholder="tLabel('placeholder.atLeastThreeChars')"
                  :items="vessels" :search-input.sync="searchVessel" @change="onChangeVessel" return-object item-text="name" clearable class="search-field">
                </v-combobox>
              </v-col>
              <v-col cols="12" class="search-field">
                <v-text-field clearable label="Voyage IN" v-model="call.voyageIn" />
              </v-col>
              <v-col cols="12" class="search-field">
                <v-text-field clearable label="Voyage OUT" v-model="call.voyageOut" />
              </v-col>
              <v-col cols="12">
                <v-autocomplete :label="tLabel('Status')" v-model="statusSelected" :items="statuses" :search-input.sync="searchStatus" 
                return-object item-text="text" item-value="value" clearable class="search-field" @change="onChangeStatus">
                <template v-slot:append> 
                  <v-icon class="cursor-pointer"> mdi-menu-down </v-icon> 
                </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="center" class="mb-4">
              <v-col cols="6" class="search-field d-flex align-center">
                <v-icon class="mr-2">mdi-calendar</v-icon>
                <DateTimeAdapter clearable label="Data arrivo da" v-model="call.etaFrom" />
              </v-col>
              <v-col cols="6" class="search-field d-flex align-center">
                <v-icon class="mr-2">mdi-calendar</v-icon>
                <DateTimeAdapter clearable label="Data arrivo a" v-model="call.etaTo" />
              </v-col>
            </v-row>

            <v-row align="center" class="mb-4">
              <v-col cols="6" class="search-field d-flex align-center">
                <v-icon class="mr-2">mdi-calendar</v-icon>
                <DateTimeAdapter clearable label="Data partenza da" v-model="call.etdFrom" />
              </v-col>
              <v-col cols="6" class="search-field d-flex align-center">
                <v-icon class="mr-2">mdi-calendar</v-icon>
                <DateTimeAdapter clearable label="Data partenza a" v-model="call.etdTo" />
              </v-col>
            </v-row>

  
          </v-container>
        </template>
      </v-navigation-drawer>
    </div>
  </template>
  
  <script>
  import DateTimeAdapter from "../../components/anagrafiche/DateTimeAdapter";

  export default {
    name: "VesselListLateralFilter",
    data() {
      return {
        drawer: false, 
        vessels: [],
        vesselSelected: null,
        searchVessel: null,
        vesselSelectedBkp: null,
        call: {
          voyageIn: null,
          voyageOut: null,
          eta: null,
          etaFrom: null, 
          etaTo: null,
          etd: null,
          etdFrom: null, 
          etdTo: null,
          date: null,
        },
        menu1: false, 
        startDateIsoFormat: null, 
        startDateFormat: '', 
        searchByDateEnabled: true, 
        statuses: [], 
        statusSelected: null, 
        searchStatus: null, 
      };
    },
    components: { DateTimeAdapter },
    emits: ["filterApplied", "errorOccurred"],
    async mounted() {
      console.log("Metodi disponibili:", this.onRefresh, this.onCleanFilters);
      console.log("dentro mounted");
      await this.fetchStatuses(); 
    },
    watch: {
      async searchVessel(newVal) {
        console.log("searchVessel", newVal);
        this.vessels = [];
        if (newVal !== null && newVal.length >= 3) {
          this.vessels = await this.$api.vesselData.getVessels(newVal);
        }
      },
      startDateIsoFormat(newVal) {
        // Formattazione della data
        if (newVal) {
          this.startDateFormat = new Date(newVal).toLocaleDateString();
        }
      }
    },
    
    methods: {
      openDrawer() {
        this.drawer = true; 
      },
      isAnyFilterApplied() {
        return (
          this.vesselSelected || 
          this.call.voyageIn || 
          this.call.voyageOut || 
          this.statusSelected || 
          this.call.eta || 
          this.call.etd ||
          this.call.etaFrom || 
          this.call.etaTo || 
          this.call.etdFrom || 
          this.call.etdTo
        );
      },

      async onApplyFilter() {
        if (!this.isAnyFilterApplied()) {
          this.$emit('errorOccurred', 'Deve essere applicato almeno un filtro');
          return;
        }

        const formatDateTimeForBackend = (date) => {
          if (!date) return null;
          const localDate = new Date(date);
          return localDate.toISOString();
        };

        const filters = {
          vessel: this.vesselSelected ? { id: this.vesselSelected.id } : null,
          voyageIn: this.call.voyageIn ? this.call.voyageIn.trim() : null,
          voyageOut: this.call.voyageOut,
          status: this.statusSelected ? this.statusSelected.value : null,
          etaFrom: formatDateTimeForBackend(this.call.etaFrom),
          etaTo:formatDateTimeForBackend(this.call.etaTo),
          etdFrom: formatDateTimeForBackend(this.call.etdFrom),
          etdTo: formatDateTimeForBackend(this.call.etdTo),
        };

        try {
          const response = await this.$api.vesselData.searchCalls(filters);
          console.log("response: ",response);
          const sanitizedData = response.map((item) => ({
            ...item,
            dischargeUti: item.dischargeUti || [],
            dischargeCountMap: item.dischargeCountMap || [],
            loadUti: item.loadUti || [],
            loadCountMap: item.loadCountMap || [],
          }));

          console.log('Filtri applicati, risposta:', sanitizedData);
          this.$emit('filterApplied', sanitizedData);
          this.drawer = false;
        } catch (error) {
          console.error('Errore nella chiamata API:', error);
        }
      },
      onChangeVessel(item) {
        console.log("onChangeVessel", item);
        if (item && typeof item === "object") {
          this.vesselSelectedBkp = { ...item };
        } else {
          this.vesselSelected = this.vesselSelectedBkp = null;
        }
      },
      startDateChanging(date) {
        console.log('Data selezionata:', date);
      },
      async fetchStatuses() {
        try {
            const response = await this.$api.vesselData.getStatuses();
            console.log("Risposta completa dell'API:", response);
            this.statuses = response; 
        } catch (error) {
            console.error("Errore nel caricamento degli StatusEnum:", error);
        }
      },
      onChangeStatus(item) {
        console.log("Status selezionato:", item);
      },
      clearError() {
        this.errorMessage = null; 
      },
    },
  };
  </script>
  
  <style scoped>
  .close-row {
    background-color: #f0f0f0; 
    width: 110%; 
  }

  .close-row .v-btn {
    padding: 0;
    margin: 0 auto;
  }
  
  .v-divider {
    margin: 20px 0;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  </style>
  