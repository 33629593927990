<template>
  <div>
    <v-container fluid v-if="showFilters">
      <v-row align="center">
        <v-col cols="2" class="d-flex align-center">
          <v-switch v-model="modeFilter" label="Show Filters" inset color="primary" style="margin-left: 10px"></v-switch>
        </v-col>

        <v-col cols="6" class="d-flex align-center">
          <v-btn color="primary" :title="tLabel('Create vessel call')" @click="goToManageCall()"> <v-icon class="pr-2">mdi-plus</v-icon>{{ tLabel("Create") }} </v-btn>
        </v-col>

        <v-col cols="3" v-if="modeFilter" class="text-right d-flex align-center justify-end">
          <VesselListLateralFilter @filterApplied="updateCallList" @errorOccurred="handleError">
            <template v-slot:button="{ onOpenDrawer }">
              <v-btn icon :title="tLabel('Applica filtri')" color="primary" @click="onOpenDrawer" class="ml-2" style="margin-right: -90px">
                <v-icon large>mdi-filter-variant</v-icon>
              </v-btn>
            </template>
          </VesselListLateralFilter>
        </v-col>

        <v-col cols="3" v-else class="d-flex align-right">
          <v-container fluid>
            <v-row align="center">
              <v-col cols="3" class="text-right">
                <v-btn small outlined fab left color="primary" @click="incrStartDate(-1)" elevation="3" :disabled="!searchByDateEnabled">
                  <v-icon x-large>mdi-chevron-left</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="8" class="text-center d-flex align-center">
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field :value="startDateFormat" persistent-hint prepend-icon="event" v-on="on" :disabled="!searchByDateEnabled" />
                  </template>
                  <v-date-picker v-model="startDateIsoFormat" no-title @input="menu1 = false" @change="startDateChanging" :disabled="!searchByDateEnabled" />
                </v-menu>
              </v-col>
              <v-col cols="1" class="text-left d-flex align-center">
                <v-btn small outlined fab right color="primary" @click="incrStartDate(1)" elevation="3" :disabled="!searchByDateEnabled">
                  <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" class="justify-end">
              <v-col cols="8" class="d-flex">
                <v-btn small :color="filterByETDFlag ? 'grey lighten-1' : 'primary'" @click="filterByETA" style="border-radius: 5px 0 0 5px; margin: 0; margin-top: -20px">
                  <span :class="filterByETDFlag ? 'grey--text' : 'white--text'">Arrival</span>
                </v-btn>
                <v-btn small :color="filterByETDFlag ? 'primary' : 'grey lighten-1'" @click="filterByETD" style="border-radius: 0 5px 5px 0; margin: 0; margin-top: -20px">
                  <span :class="!filterByETDFlag ? 'grey--text' : 'white--text'">Departure</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

      <!-- <v-col cols="6" class="d-flex align-center justify-center">
          <v-container>
            <v-row align="center">
              <v-col cols="7" class="d-flex align-center">
                <v-text-field :label="tLabel('Voyage')" v-model="searchFilter" />
              </v-col>
              <v-col cols="3" class="d-flex align-center justify-center">
                <v-btn small outlined fab color="grey" style="margin: 0 5px" @click="searchByDateEnabled = true; searchFilter = null; fetchData();" elevation="3">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn small outlined fab color="primary" style="margin: 0 5px"  @click="searchByDateEnabled = false; fetchData();" elevation="3">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col> -->
    </v-container>

    <v-container fluid v-if="modeFilter">
      <v-col cols="102" class="pt-0 mt-0">
        <v-divider></v-divider>
      </v-col>
      <v-row align="center" v-if="filteredCalls.length === 0 && !hasFilteredOnce">
        <v-col cols="12" class="text-h5 text-center font-weight-light grey lighten-4"> Inserire i filtri e applicarli per visualizzare le chiamate </v-col>
      </v-row>
      <v-row v-else-if="filteredCalls.length > 0">
        <v-col cols="12" v-for="call in filteredCalls" :key="call.id">
          <!-- {{ call }} -->
          <CallCard :call="call" @click.native="goToDetails(call.id)" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="text-h5 text-center font-weight-light grey lighten-4"> Nessuna call trovata </v-col>
      </v-row>
    </v-container>

    <v-container fluid v-else>
      <v-row align="center">
        <v-col cols="12" v-if="dailyCalls && dailyCalls !== null && dailyCalls.length > 0">
          <div v-for="dailyCall in dailyCalls" :key="dailyCall.timestamp">
            <v-container fluid>
              <v-row class="text-right">
                <v-col cols="12">
                  <span :class="`text-body-1 ${dailyCall.isToday ? 'red--text font-weight-bold' : 'font-weight-light'}`">{{ dailyCall.dateTime | formatShortDateFormat }}</span>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0">
                <v-col cols="12" class="pt-0 mt-0">
                  <v-divider :class="dailyCall.isToday ? 'red' : null"></v-divider>
                </v-col>
              </v-row>
              <v-row v-if="dailyCall.calls && dailyCall.calls !== null && dailyCall.calls.length > 0">
                <v-col cols="12" v-for="call in dailyCall.calls" :key="call.id" class="py-1">
                  <!-- {{ call }} -->
                  <CallCard :call="call" @click.native="goToDetails(call.id)" class="pointer" />
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12" class="text-h5 text-uppercase font-weight-light grey lighten-4">
                  {{ tLabel("No vessel calls.") }}
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
        <v-col cols="12" v-else>
          <div>
            <v-container>
              <v-row class="pt-0 mt-0">
                <v-col cols="12" class="pt-0 mt-0">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-h5 text-uppercase font-weight-light grey lighten-4">
                  {{ tLabel("No vessel calls.") }}
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import FormatsMixins from "../../mixins/FormatsMixins";
import VesselListLateralFilter from "./VesselListLateralFilter.vue";
import CallCard from "./CallCard";
import ToastMixin from "../../mixins/ToastMixin";

export default {
  name: "CallsDailyCalendar",
  data() {
    return {
      startDate: null,
      startDateFormat: "",
      startDateIsoFormat: "",
      dailyCalls: [],
      searchFilter: null,
      menu1: null,
      searchByDateEnabled: true,
      interval: null,
      backAttrs: {},
      filterByETDFlag: false,
      modeFilter: false,
      filteredCalls: [],
      hasFilteredOnce: false,
      errorMessage: null,
    };
  },
  props: {
    showFilters: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: null,
    },
  },
  mixins: [FormatsMixins, ToastMixin],
  components: { CallCard, VesselListLateralFilter },
  // async created() {
  //   this.interval = setInterval(async () => {
  //     try {
  //       await this.fetchData();
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }, 30000);
  // },
  async mounted() {
    this.startDate = moment().startOf("day");
    console.log("startDate", this.startDate.format("YYYYMMDD"));
    if (this.filters && this.filters !== null) {
      console.log("filters", this.filters);
      this.startDate = this.filters.startDate;
      this.searchFilter = this.filters.searchFilter;
      this.searchByDateEnabled = this.filters.searchByDateEnabled;
    }
    await this.fetchData();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    updateCallList(data) {
      console.log("dentro updateCallList");
      if (data && Array.isArray(data)) {
        this.hasFilteredOnce = true;
        this.filteredCalls = data;
      } else {
        console.warn("Dati non validi ricevuti da updateCallList:", data);
        this.filteredCalls = [];
      }
    },
    goToManageCall(callId) {
      console.log("goToManageCall", callId);
      if (!callId) {
        this.$router.push({
          name: "CreateCall",
        });
      } else {
        this.$router.push({
          name: "EditCall",
          params: {
            callId: callId,
          },
        });
      }
    },
    goToDetails(callId) {
      console.log("goToDetails", callId);
      this.$router.push({
        path: "/vessel/callDetails/" + callId,
        params: {
          attrs: this.backAttrs,
        },
      });
    },
    async fetchData() {
      this.startDateFormat = this.startDate.format("DD/MM/YYYY");
      this.startDateIsoFormat = this.startDate.format("YYYY-MM-DD");
      if (this.searchByDateEnabled || !this.searchFilter) {
        if (this.filterByETDFlag) {
          await this.setDailyCallsByETD(); // Usa la funzione per ETD
        } else {
          await this.setDailyCallsByETA(); // Usa la funzione per ETA
        }
      } else {
        await this.search();
      }
    },
    isCallInDate(call, date) {
      if (call && call.eta && call.eta !== null && moment(call.eta).format("YYYYMMDD") === date.format("YYYYMMDD")) {
        return true;
      }
      return false;
    },
    async search() {
      console.log("searchFilter", this.searchFilter);
      var calls = []; //await this.$api.railGateData.getVoyageByVoyageStartingWith(this.searchFilter);
      // console.log("voyages", voyages);

      this.dailyCalls = new Array();
      if (calls && calls !== null && calls.length > 0) {
        var days = new Array();
        calls.forEach((call) => {
          if (!days.find((d) => moment(call.eta).format("YYYYMMDD") === d)) {
            days.push(moment(call.eta).format("YYYYMMDD"));
          }
        });
        days = days.sort((a, b) => (moment(a) > moment(b) ? 1 : -1));
        // console.log("days", days);

        days.forEach((d) => {
          this.dailyCalls.push({
            timestamp: d,
            dateTime: moment(d).toDate(),
            isToday: moment().startOf("day").format("YYYYMMDD") === d,
            calls: calls.filter((v) => this.isCallInDate(v, moment(d))).sort((a, b) => (moment(a.eta) > moment(b.eta) ? 1 : -1)),
          });
        });
        console.log("------------------- searchByVoyage dailyCalls", this.dailyCalls, JSON.stringify(this.dailyCalls));
      }
    },
    async setDailyCallsByETD() {
      let dateFrom = moment(this.startDate).add(-1, "day").toISOString();
      let dateTo = moment(this.startDate).add(2, "day").subtract(1, "seconds").toISOString();

      var calls = await this.$api.vesselData.getCallsByDateRange("DEPARTURE", dateFrom, dateTo, false);
      this.dailyCalls = new Array();

      let startDateBefore1Day = moment(this.startDate).add(-1, "day");
      let startDateAfter1Day = moment(this.startDate).add(1, "day");

      let before1DayCalls = calls.filter((v) => this.isCallInETD(v, startDateBefore1Day)).sort((a, b) => (moment(a.etd) > moment(b.etd) ? 1 : -1));
      this.dailyCalls.push({
        timestamp: startDateBefore1Day.format("YYYYMMDD"),
        dateTime: startDateBefore1Day.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === startDateBefore1Day.format("YYYYMMDD"),
        calls: before1DayCalls,
      });

      let startDateCalls = calls.filter((v) => this.isCallInETD(v, this.startDate)).sort((a, b) => (moment(a.etd) > moment(b.etd) ? 1 : -1));
      this.dailyCalls.push({
        timestamp: this.startDate.format("YYYYMMDD"),
        dateTime: this.startDate.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === this.startDate.format("YYYYMMDD"),
        calls: startDateCalls,
      });

      let after1DayCalls = calls.filter((v) => this.isCallInETD(v, startDateAfter1Day)).sort((a, b) => (moment(a.etd) > moment(b.etd) ? 1 : -1));
      this.dailyCalls.push({
        timestamp: startDateAfter1Day.format("YYYYMMDD"),
        dateTime: startDateAfter1Day.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === startDateAfter1Day.format("YYYYMMDD"),
        calls: after1DayCalls,
      });
    },
    async setDailyCallsByETA() {
      let dateFrom = moment(this.startDate).add(-1, "day").toISOString();
      let dateTo = moment(this.startDate).add(2, "day").subtract(1, "seconds").toISOString();
      console.log("dateFrom", dateFrom, moment(this.startDate).add(-1, "day").format("DD/MM/YYYY HH:mm:ss"));
      console.log("dateTo", dateTo, moment(this.startDate).add(2, "day").subtract(1, "seconds").format("DD/MM/YYYY HH:mm:ss"));

      var calls = await this.$api.vesselData.getCallsByDateRange("ARRIVAL", dateFrom, dateTo, false);
      console.log("calls", calls ? calls.length : 0, calls, JSON.stringify(calls));

      this.dailyCalls = new Array();

      let startDateBefore1Day = moment(this.startDate).add(-1, "day");
      let startDateAfter1Day = moment(this.startDate).add(1, "day");

      let before1DayCalls = calls.filter((v) => this.isCallInDate(v, startDateBefore1Day)).sort((a, b) => (moment(a.eta) > moment(b.eta) ? 1 : -1));
      console.log("before1DayCalls", before1DayCalls);
      this.dailyCalls.push({
        timestamp: startDateBefore1Day.format("YYYYMMDD"),
        dateTime: startDateBefore1Day.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === startDateBefore1Day.format("YYYYMMDD"),
        calls: before1DayCalls,
      });

      let startDateCalls = calls.filter((v) => this.isCallInDate(v, this.startDate)).sort((a, b) => (moment(a.eta) > moment(b.eta) ? 1 : -1));
      console.log("startDateCalls", startDateCalls);
      this.dailyCalls.push({
        timestamp: this.startDate.format("YYYYMMDD"),
        dateTime: this.startDate.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === this.startDate.format("YYYYMMDD"),
        calls: startDateCalls,
      });

      let after1DayCalls = calls.filter((v) => this.isCallInDate(v, startDateAfter1Day)).sort((a, b) => (moment(a.eta) > moment(b.eta) ? 1 : -1));
      console.log("after1DayCalls", after1DayCalls);
      this.dailyCalls.push({
        timestamp: startDateAfter1Day.format("YYYYMMDD"),
        dateTime: startDateAfter1Day.toDate(),
        isToday: moment().startOf("day").format("YYYYMMDD") === startDateAfter1Day.format("YYYYMMDD"),
        calls: after1DayCalls,
      });

      console.log("------------------- setDailyCalls dailyCalls", this.dailyCalls, JSON.stringify(this.dailyCalls));
    },
    startDateChanging() {
      this.startDate = moment(this.startDateIsoFormat);
      this.$refs.menu1 = false;
      this.fetchData();
    },
    incrStartDate(days) {
      this.startDate = moment(this.startDateIsoFormat).add(days, "days");
      this.fetchData();
    },
    filterByETD() {
      this.filterByETDFlag = true;
      this.fetchData();
    },
    filterByETA() {
      this.filterByETDFlag = false;
      this.fetchData();
    },
    isCallInETD(call, date) {
      if (call && call.etd && call.etd !== null && moment(call.etd).format("YYYYMMDD") === date.format("YYYYMMDD")) {
        return true;
      }
      return false;
    },
    handleError(message) {
      this.showError(message);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
