export default {
  methods: {
    dischargeStatus(callStatus) {
      if (callStatus === "ADVICED" || callStatus === "ARRIVED") {
        return "TO DO";
      } else if (callStatus === "DISCHARGING") {
        return "IN PROGRESS";
      } else if (callStatus === "CANCELLED") {
        return "CANCELLED";
      }
      return "COMPLETED";
    },
    dischargeStatusIcon(status) {
      if (status === "ADVICED" || status === "ARRIVED") {
        return "mdi-clock-fast";
      } else if (status === "DISCHARGING") {
        return "mdi-progress-wrench";
      } else if (status === "CANCELLED") {
        return "mdi-cancel";
      }
      return "mdi-check-circle-outline";
    },
    dischargeStatusColor(status) {
      if (status === "CANCELLED") {
        return "red";
      } else if (status === "ADVICED" || status === "ARRIVED") {
        return "grey";
      } else if (status === "DISCHARGING") {
        return "orange";
      }
      return "green";
    },
    loadStatus(callStatus) {
      if (callStatus === "LOADING") {
        return "IN PROGRESS";
      } else if (callStatus === "LOADED" || callStatus === "DEPARTED" || callStatus === "CLOSED") {
        return "COMPLETED";
      } else if (callStatus === "CANCELLED") {
        return "CANCELLED";
      }
      return "TO DO";
    },
    loadStatusIcon(status) {
      if (status === "LOADING") {
        return "mdi-progress-wrench";
      } else if (status === "LOADED" || status === "DEPARTED" || status === "CLOSED") {
        return "mdi-check-circle-outline";
      } else if (status === "CANCELLED") {
        return "mdi-cancel";
      }
      return "mdi-clock-fast";
    },
    loadStatusColor(status) {
      if (status === "CANCELLED") {
        return "red";
      } else if (status === "ADVICED" || status === "ARRIVED" || status === "DISCHARGING" || status === "DISCHARGED") {
        return "grey";
      } else if (status === "LOADING") {
        return "orange";
      }
      return "green";
    },
    callStatusIcon(status) {
      if (status === "ADVICED" || status === "DEPARTED") {
        return "mdi-waves";
      }
      return "mdi-ferry";
    },
    callStatusColor(status) {
      if (status === "CANCELLED") {
        return "red";
      } else if (status === "ADVICED") {
        return "grey";
      } else if (status === "DEPARTED" || status === "CLOSED") {
        return "green";
      }
      return "blue";
    },
  },
};
