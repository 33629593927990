<template>
    <div class="grey lighten-4">
      <CallsDailyCalendar showFilters :filters="filters"/>
    </div>
  </template>
  
  <script>
  import CallsDailyCalendar from "../../components/vessel/CallsDailyCalendar";
  
  export default {
    name: "CallsDaily",
    components: { CallsDailyCalendar },
    props: {
      filters: {
        type: Object,
        default: null,
      },
    }
  };
  </script>
  
  <style></style>
  